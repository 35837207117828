* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}


header {
  height: 58px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  height: 58px;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

.menus {
  padding-left: 1%;
  width: 100%;
  display: flex;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 14px;
}
.tabla-datos button {
  background-color: #009879;
  border: none;
  border-radius: 0.5rem;
  width: 100%;
  background-color: #009879;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
}

.tabla-datos button:hover {
  background-color: #E26561;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
 
}

.menu-items a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  color: #000000;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 0;
  left: 10%;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #009879;
  border-radius: 0.5rem;
  display: none; 
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

nav{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background: #009879;
  color: white;

}
.nav-links{
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}
  nav button{
  background: #009879;
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  padding: 0;
  border: none;
  background: none;
  font-size: 14px;
  }
  .Salir {
    float: right;
    padding-right: 5%;

  }

  .Chart {

    padding-top: 5%;
    padding-bottom: 5%;
    margin: auto;
    width: 50%;
  }

  nav button:hover {
    color: #000000;
  }


.tabla-datos{
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;


} 
.tabla-datos thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}
.tabla-datos th,
.tabla-datos td {
  padding: 12px 15px;
}
.tabla-datos tbody tr {
  border-bottom: 1px solid #dddddd;
}

.tabla-datos tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.contenido{
  margin: 10vh;
}

.form{
  margin-left: 5%;
  min-width: 40%;
  max-width: 40%;
}
.contenedor{
display: block; 
justify-content: flex-end;
margin-top: 0vh;
}
.fixed_header{
  min-width: 40%;
  max-width: 40%;
  table-layout: fixed;
  border-collapse: collapse;
}
.fixed_header tbody{
  display:block;
  width: 70%;
  overflow:auto;
  height: 500px;
}
::-webkit-scrollbar {
  display: none;
}
.fixed_header thead tr {
  display:flex;
  max-width: 70%;
}
.fixed_header thead {
 max-width: 0px;
}

.fixed_header th, .fixed_header td {
  padding: 5px;
  text-align: center;
  width: 200px;
}

img {
  height: 100px;
  width: auto;
}

a{
  color: rgb(177, 126, 126);

}
a:link, a:visited, a:active {
  text-decoration:none;
}
.formulario input[type=number],input[type=text], select{
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.formulario {
  margin-left: 15%;
  padding: 10px;
  border: 5px solid black;
}

/* Style the submit button */
.formulario input[type=submit] {
  width: 100%;
  background-color: #009879;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
}

.formulario  textarea {
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  line-height: 30px;
  height: 100px;
  resize: none;
  width: 100%; /* or whatever width you want to set */
  }


/* Add a background color to the submit button on mouse-over */
.formulario input[type=submit]:hover {
  background-color: #E26561;
}

form label {
  font-weight:bold;
  font-size: 30px;
}

.analisis-produccion-container {
  text-align: center;
  margin: 2rem auto;
}

.analisis-produccion-container h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.form {
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background-color: #f2f2f2;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

input, select, button {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

button {
  background-color: #009879;
  color: white;
  cursor: pointer;
  border: none;
}

button:hover {
  background-color: #E26561;
}


.select-agrupamiento {
  width: 30%; /* O el tamaño que consideres apropiado */
}

.formulario-filtrado {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra los elementos a lo largo del eje principal */
}

.fechas-container {
  display: flex; /* Los hace estar uno al lado del otro */
  gap: 10px; /* Añade un pequeño espacio entre los dos */
}

.estadisticas-container {
  margin: 25px 0;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  background-color: #009879;
  color: white;
}

th, td {
  padding: 15px;
  text-align: left;
}

tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.btnbuscador {
  margin-right: 1rem;

}
